<template>
  <div class="odrder_car">
    <!-- 全部商品 -->
    <div class="order_title">
      <div class="title">
        全部商品<span class="title_tip">({{ allGoods }})</span>
      </div>
      <div v-if="orderList.length > 0" class="tip" :class="{
        tip1: typeOne,
        tip2: typeTwo,
        tip3: typeThree,
        tip4: typeFour,
      }" @click="toDeal">
        {{ !dealStatus ? "管理" : "完成" }}
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="commodity" v-if="orderList.length > 0">
      <div class="commodity_centent">
        <div class="item" v-for="(item, index) in orderList" :key="index">
          <div class="centent_left">
            <div class="item_choose">
              <el-image v-if="item.delStatus == 1 && typeOne" class="choose_img"
                :src="require('@/assets/img/textmall/moren_z.png')" @click="change(item)"></el-image>
              <el-image v-else-if="item.delStatus == 1 && typeTwo" class="choose_img"
                :src="require('@/assets/img/textmall/moren.png')" @click="change(item)"></el-image>
              <el-image v-else-if="(item.delStatus == 1 && typeFour) ||
                (item.delStatus == 1 && typeThree)
                " class="choose_img" :src="require('@/assets/img/textmall/check_big.png')"
                @click="change(item)"></el-image>
              <div class="choose_circle" @click="change(item)" v-else></div>
            </div>
            <div class="item_book">
              <el-image class="book_img" :src="item.faceImg"></el-image>
            </div>
          </div>
          <div class="centent_right">
            <div :class="dealStatus == 1 ? 'right_item' : 'right_item1'">
              <div class="right_name">
                <p class="name">{{ item.textbookName }}</p>
                <div class="num">
                  <el-input-number v-model="item.buyCount" @change="undatePrice(item)" :min="1"
                    label="描述文字"></el-input-number>
                  <div class="buyCount"></div>
                </div>
              </div>
              <div class="item_price">
                <p class="weight" :class="{
                  tip1: typeOne,
                  tip2: typeTwo,
                  weight3: typeThree,
                  tip4: typeFour,
                }">
                  {{ (item.weight * item.buyCount).toFixed(2) }}kg
                </p>
                <div class="price_he">
                  <span class="price" v-if="item.entrantsPrice || item.entrantsPrice === 0">￥{{
                    item.entrantsPrice.toFixed(2) }}</span>
                </div>
              </div>
            </div>
            <div class="item_img" v-if="dealStatus == 1">
              <el-image class="delImg" :src="require('@/assets/img/textmall/del.png')" @click="deleltById(item)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单提交 -->
    <div class="order_submit" v-if="orderList.length > 0">
      <div class="submit_all">
        <el-image class="all_img" :src="require('@/assets/img/textmall/moren_z.png')" v-if="Alldel == 1 && typeOne"
          @click="changeAll"></el-image>
        <el-image class="all_img" :src="require('@/assets/img/textmall/moren.png')" v-else-if="Alldel == 1 && typeTwo"
          @click="changeAll"></el-image>
        <el-image class="all_img" :src="require('@/assets/img/textmall/check_big.png')"
          v-else-if="(Alldel == 1 && typeFour) || (Alldel == 1 && typeThree)" @click="changeAll"></el-image>
        <div class="circle" v-else @click="changeAll"></div>
        <div class="all">全选</div>
      </div>
      <div class="submit_btn">
        <div class="btn_text" v-if="submitArr.length > 0 && dealStatus !== 1">
          已选择 <span class="piece">{{ submitAll }}</span> 件商品，合计：
          <span class="summation">{{ sumAll }}</span>
          <span class="freight" v-if="freightPrice || freightPrice === 0">
            运费： <span class="freight1">￥{{ freightPrice.toFixed(2) }}</span>
          </span>
        </div>
        <el-button class="submit_order" v-if="!dealStatus" @click="addTextBookOrder" :disabled="submitDisbled">
          提交订单
        </el-button>
        <div class="submit_del" v-else @click="delAll">删除</div>
      </div>
    </div>
    <div class="not_available" v-else>
      <el-empty description="暂无数据"></el-empty>
    </div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="356px" center v-if="typeOne" custom-class="hint_gb1">
      <span v-if="repertory !== ''">您选择的{{ repertory }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="knowClick">我知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="356px" center v-else-if="typeTwo"
      custom-class="hint_gb2">
      <span v-if="repertory !== ''">您选择的{{ repertory }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="knowClick">我知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="356px" center v-else-if="typeThree"
      custom-class="hint_gb3">
      <span v-if="repertory !== ''">您选择的{{ repertory }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="knowClick">我知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="356px" v-else-if="typeFour" center
      custom-class="hint_gb4">
      <span v-if="repertory !== ''">您选择的{{ repertory }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="knowClick">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { CouponsClient } from "@/api/mine/couponsClient";
import { getDomain } from "@/api/cookies";
import {
  selectTextbookPcApiPage,
  deleteStuShoppingTrolley,
  updateStuShoppingTrolley,
  addTextBookOrder,
  getCurSelfBookConfigPc,
  selectTextbookCount,
} from "@/api/mykecheng";
const couponsClient = new CouponsClient();
export default {
  data() {
    return {
      num: 0,
      orderList: [],
      Alldel: 0,
      dealStatus: 0,
      productIds: [],
      total: 0,
      submitArr: [],
      repertory: "",
      centerDialogVisible: false,
      // class判断
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
      // freightList: [],
      itemObj: {},
      freight: 0,
      freightPrice: "",
    };
  },
  created() {
    this.getList();
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟
     * entrantsType=3   =>  聚学U
     * entrantsType=5   =>  高校共建(合伙共建)
     * entrantsType=1   =>  大客户
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    }
  },
  computed: {
    // 全部商品
    allGoods() {
      let result = 0;
      this.orderList.forEach((item) => {
        result += item.buyCount;
      });
      this.$store.commit("getNum", result);
      return result;
    },
    // 提交订单
    submitAll() {
      let res = 0;
      this.submitArr.forEach((item) => {
        res += item.buyCount;
      });
      if (res == 0) return "";
      else return res;
    },
    // 合计价格
    sumAll() {
      let res = 0;
      this.freight = 0;
      this.submitArr.forEach((item) => {
        this.freight += item.buyCount * item.weight;
        res += item.price * item.buyCount;
      });
      if (res == 0) return "0.00";
      else return res.toFixed(2);
    },
    // 按钮禁用
    submitDisbled() {
      if (this.submitArr.length > 0) return false;
      else return true;
    },
  },
  watch: {
    freight: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.freightCom(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      const res = await selectTextbookPcApiPage();
      if (res.code == 0) {
        this.orderList = res.data;
        this.orderList.forEach((element) => {
          if (element.inventory < element.buyCount) {
            this.itemObj.id = element.id;
            this.repertory +=
              "《" +
              element.textbookName +
              "》" +
              `库存仅剩 ${element.inventory} 本 `;
            this.centerDialogVisible = true;
          }
        });
      }
    },
    totalNum(val, item) {
      return (val * item).toFixed(2);
    },
    async addTextBookOrder() {
      const res = await addTextBookOrder(this.submitArr, 1);
      if (res.code == 0) {
        this.goNext(`/mall/orderView?id=${res.data}`);
      } else if (res.code === 500) {
        this.$message.error(res.msg);
      }
    },
    async deleltById(val) {
      const data = { productIds: val.id };
      const res = await deleteStuShoppingTrolley(data);
      if (res.code == 0) {
        this.$message.success("删除成功");
        const shopRes = await selectTextbookCount();
        this.$store.commit("getNum", shopRes.data);
        if (this.submitArr.length > 1) {
          this.submitArr.forEach((item, index) => {
            if (val.id !== item.id) {
              this.submitArr = this.submitArr.splice(index, 1);
            }
          });
        } else if (this.submitArr.length === 1) {
          this.submitArr.pop();
        }
        this.getList();
      }
    },
    // 数量改变
    async undatePrice(val) {

      if (!val.buyCount) {
        val.buyCount = 1
      }
      this.itemObj = val;
      const res = await updateStuShoppingTrolley({
        productId: val.id,
        buyCount: val.buyCount,
      });
      this.repertory = "";
      this.orderList.forEach((element) => {
        if (element.id === val.id && element.inventory < val.buyCount) {
          this.repertory +=
            "《" +
            element.textbookName +
            "》" +
            `库存仅剩 ${element.inventory} 本 `;
          this.centerDialogVisible = true;
        }
      });
    },
    toDeal() {
      this.dealStatus = Number(!this.dealStatus);
      this.Alldel = 0;
      this.orderList.forEach((item) => {
        item.delStatus = 0;
      });
    },
    // 运费计算
    async freightCom(val) {
      const res = await getCurSelfBookConfigPc(val);
      if (res.data.isExemption === 1) {
        if (this.sumAll < res.data.exemption) {
          this.freightPrice = res.data.configList[0]?.freight;
        } else {
          this.freightPrice = 0;
        }
      } else {
        this.freightPrice = res.data.configList[0].freight;
      }
    },
    async delAll() {
      this.orderList.forEach((element) => {
        if (element.delStatus) {
          this.productIds.push(element.id);
        }
      });
      const data = { productIds: this.productIds.join(",") };
      const res = await deleteStuShoppingTrolley(data);
      if (res.code == 0) {
        this.submitArr = [];
        this.$message.success("删除成功");

        this.getList();
      }
    },
    async change(val) {
      if (val.delStatus == 1) {
        if (this.submitArr.length > 1) {
          this.submitArr.forEach((item, index) => {
            if (val.id === item.id) {
              this.submitArr.splice(index, 1);
            }
          });
        } else if (this.submitArr.length === 1) {
          this.submitArr.pop();
        }
      } else {
        this.submitArr.push(val);
      }
      this.total = 0;
      val.delStatus = Number(!val.delStatus);
      const someResult = this.orderList.every((item) => item.delStatus == 1);
      this.orderList.forEach((element) => {
        if (element.delStatus) {
          this.total = this.total + element.delStatus;
        }
      });
      if (someResult) {
        this.Alldel = 1;
      } else {
        this.Alldel = 0;
      }
    },
    changeAll() {
      this.total = 0;
      this.submitArr = [];
      if (this.Alldel) {
        this.orderList.forEach((element) => {
          element.delStatus = 0;
        });
        this.total = this.orderList.length;
      } else {
        this.submitArr = this.submitArr.concat(this.orderList);
        
        this.orderList.forEach((element) => {
          element.delStatus = 1;
        });
      }
  
      this.Alldel = Number(!this.Alldel);
    },
    // 我知道了
    knowClick() {
      this.orderList.forEach(async (element) => {
        
        if (element.id === this.itemObj.id && element.inventory > 0) {
          element.buyCount = element.inventory;
          await updateStuShoppingTrolley({
            productId: element.id,
            buyCount: element.buyCount,
          });
        }
        // else if (element.id === this.itemObj.id && element.inventory === 0) {
        //   element.buyCount = 0
        // }
      });
      this.centerDialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.odrder_car {
  width: 100%;

  // padding-bottom: 275px;
  .order_title {
    width: 1200px;
    margin: 20px auto 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;

      .title_tip {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #444444;
        margin-left: 10px;
      }
    }

    .tip {
      width: 28px;
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      margin-right: 30px;
      cursor: pointer;
    }
  }

  .commodity {
    width: 1200px;
    margin: 10px auto;
    background: #ffffff;
    border-radius: 7px 7px 7px 7px;
    position: relative;
    padding: 0 20px;

    .commodity_centent {
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .centent_left {
          display: flex;
          justify-content: center;
          align-items: center;

          .item_choose {
            margin-right: 20px;

            .choose_img {
              width: 20px;
              height: 20px;
            }

            .choose_circle {
              width: 20px;
              height: 20px;
              background: #ffffff;
              opacity: 1;
              border: 1px solid #999999;
              border-radius: 50%;
            }
          }

          .item_book {
            width: 114px;
            height: 114px;
            background: #f5f7f9;
            border-radius: 5px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .book_img {
              width: 79px;
              height: 104px;
            }
          }
        }

        .centent_right {
          width: 1025px;
          height: 150px;
          margin-left: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eee;
          position: relative;

          .right_item,
          .right_item1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .right_name {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              .name {
                width: 700px;
                height: 45px;
                white-space: normal;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .num {
                width: 170px;
                position: relative;

                /deep/ .el-input-number {
                  width: 170px;
                  border: none;
                  z-index: 10;
                  background-color: transparent;

                  .el-input {
                    font-size: 20px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000 !important;
                  }

                  .el-input__inner {
                    border: none;
                    background-color: transparent;
                  }

                  .el-input-number__decrease,
                  .el-input-number__increase {
                    background-color: transparent;
                    border: none;
                    font-size: 20px !important;

                    .el-icon-minus,
                    .el-icon-plus {
                      font-weight: bold !important;
                    }
                  }
                }

                .buyCount {
                  position: absolute;
                  width: 81px;
                  height: 36px;
                  background: #eeeeee;
                  border-radius: 0px 0px 0px 0px;
                  opacity: 1;
                  top: 1px;
                  left: 45px;
                }
              }
            }

            .item_price {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 30px;

              .weight {
                font-size: 14px;
                font-family: MicrosoftYaHei-, MicrosoftYaHei;
                font-weight: normal;
              }

              .price_he {
                width: 170px;
                text-align: center;
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #333333;

                .price {
                  font-size: 16px;
                  font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
                  font-weight: 600;
                  color: #333333;
                  // margin-right: 35px;
                }
              }
            }
          }

          .right_item {
            margin-right: 40px;
          }

          .item_img {
            position: absolute;
            right: 0px;
            width: 300px;
            height: 96px;
            line-height: 115px;
            text-align: right;
            z-index: 10;
            background: linear-gradient(271deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%);

            // margin-left: 20px;
            .delImg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .item:nth-last-child(1) {
        .centent_right {
          border: none;
        }
      }

      // border-bottom: 2px solid #eee;
    }
  }

  .tip1 {
    color: #ff5d51;
  }

  .tip2 {
    color: #00ab8c;
  }

  .tip3 {
    color: #006de7;
  }

  .weight3 {
    color: #2586f5;
  }

  .tip4 {
    color: #1061ff;
  }

  .order_submit {
    width: 1200px;
    height: 90px;
    background: #ffffff;
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .submit_all {
      display: flex;
      align-items: center;

      .all_img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #999999;
        margin-right: 10px;
      }

      .all {
        color: #999;
      }
    }

    .submit_btn {
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      display: flex;
      align-items: center;

      .btn_text {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #777777;

        .piece {
          color: #ff5d51;
        }

        .summation {
          font-size: 30px;
          font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
          font-weight: bold;
          color: #f9431e;
          margin-right: 30px;
        }

        .freight {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-right: 20px;

          .freight1 {
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .submit_order,
      .submit_del {
        width: 220px;
        height: 48px;
        color: #fff;
        background: linear-gradient(88deg, #ff6d1e 0%, #fe420e 100%);
        border-radius: 24px 24px 24px 24px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
  }

  .el-dialog__wrapper {
    top: 20%;
  }

  /deep/ .el-dialog {
    height: auto;
    // background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    position: relative;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
      letter-spacing: 2px;
    }

    .el-dialog__body {
      margin-top: 26px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 27px;
      letter-spacing: 1px;
      text-align: center;
    }

    .el-dialog__close {
      font-size: 22px;
    }

    .el-dialog__footer {
      margin-top: 20px;
      padding-bottom: 30px;

      .el-button {
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        line-height: 0px;
      }
    }
  }

  /deep/ .hint_gb1 {
    background: linear-gradient(180deg,
        rgba(255, 227, 225, 1) 0%,
        rgba(255, 255, 255, 1) 100%) !important;

    .el-button {
      background-color: transparent !important;
      border: none;
      color: #ff5d51 !important;
    }
  }

  /deep/ .hint_gb2 {
    background: linear-gradient(180deg,
        rgba(209, 240, 234, 1) 0%,
        rgba(255, 255, 255, 1) 100%) !important;

    .el-button {
      background-color: transparent !important;
      border: none;
      color: #00ab8c !important;
    }
  }

  /deep/ .hint_gb3 {
    background: linear-gradient(180deg,
        rgba(205, 226, 250, 1) 0%,
        rgba(255, 255, 255, 1) 100%) !important;

    .el-button {
      background-color: transparent !important;
      border: none;
      color: #006de7 !important;
    }
  }

  /deep/ .hint_gb4 {
    background: linear-gradient(180deg,
        rgba(210, 225, 255, 1) 0%,
        rgba(255, 255, 255, 1) 100%) !important;

    .el-button {
      background-color: transparent !important;
      border: none;
      color: #1061ff !important;
    }
  }
}

.not_available {
  text-align: center;
  padding-top: 100px;
  font-size: 14px;
  color: #999999;
}
</style>
